import React, { useState } from 'react'
import { connect } from 'react-redux'

export const SectionTitle = ({
  features,
  isAdmin,
  section,
  formTemplate,
  showBom,
  showAdjustment,
  duplicateSection
}) => {
  const { duplicate, show_adjustment_btn } = section.features
  const { batch_adjustments, bom_page } = formTemplate.features
  const [sampleBtnDisabled, setSampleBtnDisabled] = useState(false)

  const handleDuplicateSection = async () => {
    setSampleBtnDisabled(true)
    await duplicateSection()
    setSampleBtnDisabled(false)
  }

  // console.log('section', section)
  return (
    <div className="pb-3 mb-4 border-bottom d-flex justify-content-between align-items-center">
      <div>
        <p
          className={`sop-section-title ${
            section.description && 'sop-section-title--sm'
          }`}
        >
          {section.name}
        </p>
        {section.description && (
          <p className="sop-section-subtitle">{section.description}</p>
        )}
      </div>

      <div>
        {bom_page && (
          <button onClick={showBom} className="ml-4 btn btn-outline-primary">
            View BOM
          </button>
        )}
        {batch_adjustments && (isAdmin || show_adjustment_btn) && (
          <button onClick={showAdjustment} className="ml-4 btn btn-primary">
            Add Adjustments
          </button>
        )}
        {/* Confirm duplication functionality */}
        {duplicate && (
          <button
            onClick={handleDuplicateSection}
            className="ml-4 btn btn-outline-secondary"
            disabled={sampleBtnDisabled}
          >
            {sampleBtnDisabled ?
            <div className='tw-animate-pulse'>Adding...</div>
            : 'Add Sample'}
          </button>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  features: state.formInstanceReducer.activeForm.form_template.features
})

export default connect(mapStateToProps)(SectionTitle)
