//This component Automatically formatting number fields - Forms
import React, { useState } from 'react'
import { horizontalClasses } from '../FormFields'
import { FieldError } from './FieldError'

const FormatNumbersFields = ({ formName, label, value, onChange, onBlur, classes, error }) => {
  const [rawValue, setRawValue] = useState(value)
  const isLineClearancePreWeighForm = formName === 'LINE CLEARANCE PRE-WEIGH'
  const isFillingLineClearanceForm = formName === 'Filling Line Clearance'

  // This is a function that format number with commas
  const formatNumbersForms = (value) => {
    return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }
  const handleChange = (event) => {
    let value = event?.target.value

    // Validate the numbers and keep only numeric values
    let numericValue = value.replace(/[^0-9]/g, '')

    // Then Update the raw value state
    setRawValue(numericValue) //ui
    onChange(numericValue) // data on the database
  }

  return (
    <div className={`my-3  ${classes || 'col-sm-6'}`}>
      <div className={`${(isLineClearancePreWeighForm || isFillingLineClearanceForm) ? 'tw-grid tw-grid-cols-2' : 'form-input'}`}>
        <div className={`tw-pb-1 tw-flex ${!error?'tw-items-center':''}`}>
          {label && <label className="mb-0">{label}:</label>}

        </div>

        <div>
          <input
            className={`form-control ${error && 'border-danger'}`}
            type="text"
            value={formatNumbersForms(rawValue)}
            onChange={handleChange}
            onBlur={onBlur}
          />
          {error && <FieldError error={error} />}
        </div>
      </div>
    </div>
  )
}

export default FormatNumbersFields
