
import React from "react";
import { horizontalClasses } from "../FormFields";

interface CheckboxProps {
    label: string
    value: string
    onChange: (val: string) => void
    horizontal?: boolean
    classes?: string
}

export const Checkbox = ({ label, value, horizontal, onChange, classes }: CheckboxProps) => {
    const [checked, setChecked] = React.useState(!!value);
    const handleChange = () => {
        onChange(!checked ? 'true' : '');
        setChecked(!checked);
    }
    return (
    <div
                className={`checkbox-note form-input my-3 ${classes || "col-sm-12"} ${
                    horizontal && horizontalClasses
                }`}
            >
                <label className="font-weight-normal">{label}</label>
                <input
                    checked={checked}
                    onChange={handleChange}
                    type='checkbox'
                    className='form-control form-control-input'
                />
            </div>
)};