import { useState, useEffect, useCallback } from 'react'
import debounce from 'lodash/debounce'

import * as fieldInstanceApi from '../api/fieldInstanceApi'

import {
  FormInstanceProps,
  FieldInstanceProps,
} from '../formSchema'
import {
  findMissingFields,
  SORT_FIELD_INSTANCES
} from '../utils/formUtils'
import { fetchSectionData } from '../utils/fetchSectionData'
import { getActiveSectionInstance } from '../utils/activeSectionInstance'

const useFormSection = ({
  user,
  formInstance,
  formInstanceActions
}: {
  user: any
  formInstance: any
  formInstanceActions: typeof import('../actions/formInstanceAction')
}) => {


  const [batchAdjust, setBatchAdjust] = useState(false)

  const [fieldInstances, setFieldInstances] = useState<FieldInstanceProps[]>([])

  const [error, setError] = useState('')
  const [saving, setSaving] = useState(false)

  // Update field instances on when the section loads
  useEffect(() => {
    console.log(formInstance)
    if (formInstance?.active_field_instances) {
      setFieldInstances(formInstance?.active_field_instances?.sort(SORT_FIELD_INSTANCES))
    }
  }, [formInstance.active_field_instances])



  const debouncedSaveField = useCallback(
    debounce(async (fieldInstance) => {
      // Save field instance
      setSaving(true)

      // require a form instance to save
      if (!formInstance) {
        return
      }

      // Update the field instance on server
      try {
        await fieldInstanceApi.updateFieldInstance(
          fieldInstance.id,
          fieldInstance
        )

        const {field} = fieldInstances.find(field => field.id === fieldInstance.id) as FieldInstanceProps

        // If field instance is header or global refetch the form instance
        if (field.features.header || field.features.global) {
          formInstanceActions.fetchFormInstance(formInstance.id)
        }

      } catch (error) {
        console.error('Failed to update field instance', error)
      } finally {
        setSaving(false)
      }

    }, 200),
    [fieldInstances]
  )

  const handleFieldUpdate = (fieldInstance) => {
    setFieldInstances(
      (prev) =>
        prev &&
        prev.map((instance) =>
          instance.id === fieldInstance.id
            ? {
                ...instance,
                error: '',
                value: fieldInstance?.value,
                updated_at: new Date().toISOString(),
                updated_by: user.user_id
              }
            : instance
        )
    )

    debouncedSaveField(fieldInstance)
  }

  const handleMissingFields = () => {
    const incompleteFields = findMissingFields(fieldInstances)

    if (incompleteFields?.length > 0) {
      setError('Please fill out all required fields')

      const incompleteIds = incompleteFields.map(
        (fieldInstance) => fieldInstance.id
      )
      setFieldInstances((prev) =>
        prev.map((instance) =>
          incompleteIds.includes(instance.id)
            ? { ...instance, error: 'Field required' }
            : instance
        )
      )

      return false
    } else {
      setError('')
      return true
    }
  }

  const validateSection = (): boolean => {
    if (!handleMissingFields()) {
      return false
    }

    clearFieldInstances()
    return true
  }

  const toggleBatchAdjust = async () => {
    const showAdjustment = !batchAdjust
    setBatchAdjust(showAdjustment)

    // Clear fields to prevent errors
    clearFieldInstances()

    // Load the batch adjustment section fields
    if (showAdjustment) {
      const batchAdjSection = formInstance.section_instance.find(
        (sectionInstance) =>
          sectionInstance.section &&
          sectionInstance.section.features.batch_adjustment
      )

      // Get the batch adjustment section fields manually
      if (batchAdjSection) {
        console.log('Batch Adjustment Section', batchAdjSection, formInstance)
        const { value: fields } = (await fetchSectionData(
          formInstance,
          batchAdjSection
        )) as any
        setFieldInstances(fields)
      }

      return
    }
    console.log('ACtive section', formInstance)
    // Load the active section fields and adjust manually
    const activeSection = getActiveSectionInstance(formInstance)
    const { value: fields } = (await fetchSectionData(
      formInstance,
      activeSection
    )) as any

    setFieldInstances(fields)
  }

  const addAdjustmentInstances = (fieldInstances) => {
    setFieldInstances((prev) => [...prev, ...fieldInstances])
  }

  const clearFieldInstances = () => {
    setFieldInstances([])
  }


  return {
    fieldInstances,
    handleFieldUpdate,
    clearFieldInstances,
    error,
    saving,
    validateSection,
    batchAdjust,
    toggleBatchAdjust,
    addAdjustmentInstances
  }
}

export default useFormSection
