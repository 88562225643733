import {formInstanceConstants} from '../constants/formInstance.constants';
import { FormInstanceProps } from '../formSchema';



interface FormInstanceReducer {
    instances: FormInstanceProps[],
    activeForm: any,
}

const initialState: FormInstanceReducer = {
    instances: [],
    activeForm: {} as FormInstanceProps,
};

export const formInstanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case formInstanceConstants.FETCH_FORM_INSTANCES_FULFILLED:
      state = {
        instances: action.payload,
        activeForm: {} as FormInstanceProps
      }
      return state

    // This is for one singular form instance
    case formInstanceConstants.FETCH_FORM_INSTANCE_FULFILLED:
      // Transform sections to be sorted by super_order_index
      state = {
        ...state,
        activeForm: {
          ...state.activeForm,
          ...action?.payload,
          section_instance: action?.payload.section_instance,
          section_instances: action?.payload?.section_instance
        }
      }
      return state

    case formInstanceConstants.UPDATE_FORM_INSTANCE_FULFILLED:
      state = {
        ...state,
        activeForm: {
          ...state.activeForm,
          ...action.payload
        }
      }
      return state

    case formInstanceConstants.ARCHIVE_FORM_INSTANCE_FULFILLED:
      return {
        ...state,
        instances: state.instances.filter(
          (formInstance) => formInstance.id !== action.payload.id
        )
      }

    case formInstanceConstants.CREATE_FORM_INSTANCE_FULFILLED:
      return state

    case formInstanceConstants.FETCH_FORM_INSTANCE_SECTION_FULFILLED:
      state = {
        ...state,
        activeForm: { ...state.activeForm, active_field_instances: action.payload, section: action.payload }
      }
      return state

    default:
      return state
  }
}
