import React from 'react'

interface HeadingProps {
  classes: string
  value: string
}

export const Heading = ({ classes, value }: HeadingProps) => (
  <p className={`sop-heading ${classes}`}>{value.replace(/\\n/g, '\n')}</p>
)
