import { browserHistory } from 'react-router';

import store from "../store/store"
import * as FormActions from "../actions/formInstanceAction"
import { fetchSectionData } from "./fetchSectionData";
import { userData } from './fetchUserData';
import { getPageSection } from './activePageSection';
import { getActiveSectionInstance } from './activeSectionInstance';


export const fetchFormData = async ({ params }) => {
  console.log(store.getState())
  let formInstance = store.getState().formInstanceReducer.activeForm
  const userList = store.getState().userReducer.userList

  console.log('Fetching Form Data')
  console.log('Form Instance:', formInstance)
  if (!userList || userList.length === 0) {
    // fetch organization users
    userData();
  }
 
  // Get form instance if it doesn't exist
  if (!formInstance.id || formInstance.id !== params.id) {
    // fetch form instance
    formInstance = (
      (await store.dispatch(FormActions.fetchFormInstance(params.id))) as any
    ).value
  }

  if (!params.page) {
    // Get active section
    const activeSection = getActiveSectionInstance(formInstance)

    // Update URL to include page)
    browserHistory.replace(
      `/forms/${formInstance.id}/${activeSection?.super_order_index ?? 1}`
    )

    return
  }

  const activeSection = getPageSection(formInstance, parseFloat(params.page))
  if (!activeSection) {
    // fetch form instance
    formInstance = (
      (await store.dispatch(FormActions.fetchFormInstance(params.id))) as any
    ).value
  }

  fetchSectionData(formInstance, activeSection)

}
