import React from 'react'
import { FormInstanceProps, FormTemplateProps } from '../../formSchema'
import '../../styles/SOPForms.css'

import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import * as FormTemplateActions from '../../actions/formTemplateAction'
import * as FormInstanceActions from '../../actions/formInstanceAction'

import FormHeader from './FormHeader'
import FormSection from './FormSection'
import { browserHistory } from 'react-router'
import { NotFound } from '../NotFound'

type StateProps = {
  user: any
  org: any
  params: { [key: string]: any }
  formTemplate: FormTemplateProps
  formInstance: FormInstanceProps
}

type DispatchProps = {
  formTemplateActions: typeof FormTemplateActions
  formInstanceActions: typeof FormInstanceActions
}

type Props = StateProps & DispatchProps

export const SOPFormPage = ({
  // formTemplate,
  formInstance,
  params,
  formInstanceActions
}: Props) => {
  // Submit form
  const submitForm = async () => {
    // TODO: Decide if form is completed or rejected
    const status = 'completed'
    // Save Form to DB
    await formInstanceActions.updateFormInstance(formInstance.id, {
      status: status
    })

    browserHistory.push('/forms')
  }

  // If form data is not loaded
  if (!formInstance || !formInstance.id) {
    return <NotFound />
  }

  return (
    <main id="sops" className={`app-content d-flex flex-column `}>
      {/* {formInstance.adjustment_required && <AdjustmentNotification />} */}

      <FormHeader />

      <FormSection params={params} submitForm={submitForm} />
    </main>
  )
}

const mapStateToProps = (state, ownProps): StateProps => ({
  ...ownProps,
  formTemplate: state.formTemplateReducer[0],
  formInstance: state.formInstanceReducer.activeForm
})

const mapDispatchToProps = (dispatch): DispatchProps => ({
  formTemplateActions: bindActionCreators(FormTemplateActions, dispatch),
  formInstanceActions: bindActionCreators(FormInstanceActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(SOPFormPage)
