export const getPreviousSection = (formInstance, index) => {
  // if (index < 0) return null;

  const prevSection = formInstance.section_instance[index];

  if (!prevSection || !prevSection.super_order_index) {
    return getPreviousSection(formInstance, index - 1);
  }

  return prevSection;
};

export const getNextSection = (formInstance, index) => {
  if (index > formInstance.section_instance.length - 1) return null;

  const nextSection = formInstance.section_instance[index];
  


  if (!nextSection || !nextSection.super_order_index) {
    return getNextSection(formInstance, index + 1);
  }

  return nextSection;
};

export const getActiveIndex = (formInstance, sectionId) => {
  return formInstance.section_instance.findIndex(
    (instance) => instance.id === sectionId
  );
};