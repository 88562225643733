import React from 'react'
import FieldTypes from '../FormFields'
import {
  FieldInstanceProps
} from '../../../formSchema'
import { mapToFieldInstances } from '../../../utils/formUtils'

type FieldGroupProps = {
  instance: FieldInstanceProps
  groupInstances: FieldInstanceProps[]
  standardYield?: string
  onChange: ({ id, value }: { id: string; value: string }) => void
}

export const FieldGroup = ({
  instance,
  groupInstances,
  standardYield,
  onChange
}: FieldGroupProps) => {

  // Group classes
  const horizontal = instance.field.features.horizontal;
  const tableHeaderClass = instance.field.name.includes('table-heading') ? 'tw-bg-[#E6EEFE]' : '';
  const verticalFormGroup = !horizontal ? 'flex-column align-items-start' : '';
  const gridClasses = horizontal ? `tw-grid` : '';
  // Individual Field classes
  const colClass = !horizontal ? 'col-sm-4' : `col-sm-12`;

  return (
    <div
      className={`${tableHeaderClass || ''} ${verticalFormGroup || ''} ${gridClasses} my-0`}
      style={{ gridTemplateColumns: `repeat(${(groupInstances.length >= 3) ? groupInstances.length : 3}, 1fr)` }}>
      {/* When grid only has two columns then it only renders two columns, but causes elements incolumn stretch. Something to think about */}

      {groupInstances.map((instance) =>
        instance?.field?.field_type === 'group' ? (
          <FieldGroup
            key={instance.id}
            instance={instance}
            groupInstances={mapToFieldInstances(
              groupInstances,
              instance.field.children
            )}
            standardYield={standardYield}
            onChange={onChange}
          />
        ) : (
          <FieldTypes
            instance={instance}
            key={instance.id}
            onChange={onChange}
            classes={colClass}
          />
        )
      )}
    </div>
  )
}
