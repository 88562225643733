import React, { useState } from 'react'

const mapTitle = {
  ready: 'Ready to Start',
  in_progress: 'In Progress',
  completed: 'Completed',
  rejected: 'Rejected'
}

export const Accordion = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(true)

  const toggleAccordion = () => {
    setIsOpen(!isOpen)
  }
  return (
    <div className="my-4">
      <button
        onClick={toggleAccordion}
        className="px-2 py-1 mb-2 btn accordian-title"
      >
        <i
          className={`mr-2 fa fa-angle-down fa-lg accordian-icon ${
            !isOpen ? 'rotate-90' : ''
          }`}
        ></i>{' '}
        {mapTitle[title]}
      </button>
      {isOpen && children}
    </div>
  )
}
