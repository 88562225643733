import React, { useCallback, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import swal from '@sweetalert/with-react';

import { NotFound } from '../../NotFound';
import { browserHistory } from 'react-router';

import SectionTitle from './SectionTitle';
import FieldList from './FieldList';
import { FormNavigator } from '../FormNavigator';
import { BatchAdjustmentForm } from '../BatchAdjustment';
import LoadingMessage from '../../LoadingMessage/LoadingMessage';
import useFormSection from '../../../hooks/useFormSection';
import { isAdmin } from '../../../utils/utils';
import * as FormInstanceActions from '../../../actions/formInstanceAction';
import FormCommentsContainer from './FormComments';
import { BillOfMaterials } from '../BillOfMaterials';
import { findMissingFields } from '../../../utils/formUtils';
import { getActiveIndex, getNextSection, getPreviousSection } from '../../../utils/sectionUtils';
import { SectionInstanceProps } from '../../../formSchema';
import { duplicateSectionInstance } from '../../../actions/sectionInstanceAction';

const FormSection = ({
  user,
  formInstance,
  formInstanceActions,
  submitForm,
  params,
}) => {
  const {
    fieldInstances,
    handleFieldUpdate,
    clearFieldInstances,
    saving,
    error,
    validateSection,
    batchAdjust,
    toggleBatchAdjust,
    addAdjustmentInstances,
  } = useFormSection({
    user,
    formInstance,
    formInstanceActions,
  });

  // Define all hooks outside any conditions
  const returnToDashboard = useCallback(() => {
    clearFieldInstances();
    browserHistory.push(`/forms`);
  }, [clearFieldInstances]);

  const nonBatchSections = useMemo(() => {
    return formInstance.section_instance.filter(
      (section) => !section.section.features.batch_adjustment
    );
  }, [formInstance]);

  const maxPageIndex = formInstance.section_instance.length - 1;
  const page = parseFloat(params.page);

  const sectionInstance = useMemo(
    () =>
      formInstance.section_instance.find(
        (section) => section?.super_order_index === page
      ) || { section: false },
    [formInstance, page]
  );

  const activeIndex = useMemo(() => {
    return getActiveIndex(formInstance, sectionInstance.id);
  }, [formInstance, sectionInstance]);

  const handleBack = useCallback(async () => {
    if (!activeIndex) {
      returnToDashboard();
    } else {
      const prevSection = getPreviousSection(formInstance, activeIndex - 1);
      if (!prevSection) {
        returnToDashboard();
      } else {
        clearFieldInstances();
        await formInstanceActions.updateFormInstance(formInstance.id, {
          active_section_id: prevSection.id,
        });

        browserHistory.push(
          `/forms/${formInstance.id}/${prevSection.super_order_index}`
        );
      }
    }
  }, [activeIndex, formInstance, returnToDashboard, clearFieldInstances, formInstanceActions, getPreviousSection]);

  const handleNext = useCallback(async () => {
    if (!validateSection()) return;

    if (page === maxPageIndex) {
      submitForm();
    } else {
      const nextSection = getNextSection(formInstance, activeIndex + 1);

      if (!nextSection) {
        submitForm();
      } else {
        clearFieldInstances();
        await formInstanceActions.updateFormInstance(formInstance.id, {
          active_section_id: nextSection.id,
        });

        browserHistory.push(`/forms/${formInstance.id}/${nextSection.super_order_index}`);
      }
    }
  }, [validateSection, page, maxPageIndex, submitForm, formInstance, activeIndex, clearFieldInstances, formInstanceActions, ]);

  const handleBOM = useCallback(() => {
    return swal(<BillOfMaterials formInstance={formInstance} />, {
      buttons: false,
      className: 'tw-w-fit',
    });
  }, [formInstance]);

  const duplicateSection = useCallback(async () => {
    const incompleteFields = findMissingFields(fieldInstances);
    if (incompleteFields?.length > 0) return;

    const newSection: SectionInstanceProps = await duplicateSectionInstance({
      id: sectionInstance.id,
    }) as any;

    formInstanceActions.updateFormInstance(formInstance.id, {
      active_section_id: newSection.id,
    });

    browserHistory.push(`/forms/${formInstance.id}/${newSection.super_order_index}`);
  }, [fieldInstances, sectionInstance, formInstance, formInstanceActions]);

  if (!sectionInstance?.section && !fieldInstances) return <NotFound />;
  if (fieldInstances.length <= 0) return <LoadingMessage />;

  const standardYield =
    formInstance.global_fields.find((field) => field.name === 'Standard Yield')
      ?.value || '';

  const Navigator = () => (
    <FormNavigator
      index={page}
      maxIndex={maxPageIndex}
      onBack={handleBack}
      onNext={handleNext}
    >
      <div className="mr-4">
        {error && <p className="mb-0 text-danger">{error}</p>}
        <p className="mb-0">
          {saving ? 'Saving...' : `Saved: ${moment(formInstance.updated_at).format('MM/DD/YYYY h:mm:ss a')}`}
        </p>
      </div>
    </FormNavigator>
  );

  if (batchAdjust) {
    return (
      <>
        <BatchAdjustmentForm
          batchAdjustmentFieldInstances={fieldInstances}
          formTemplate={formInstance.form_template}
          handleFieldUpdate={handleFieldUpdate}
          addAdjustmentInstances={addAdjustmentInstances}
        />
        <FormNavigator
          index={1}
          maxIndex={1}
          onBack={toggleBatchAdjust}
          onNext={toggleBatchAdjust}
        >
          <div className="mr-4">
            {error && <p className="mb-0 text-danger">{error}</p>}
            <p className="mb-0">
              {saving ? (
                'Saving...'
              ) : (
                <>
                  Saved:{' '}
                  {moment(formInstance.updated_at).format(
                    'MM/DD/YYYY h:mm:ss a'
                  )}
                </>
              )}
            </p>
          </div>
        </FormNavigator>
      </>
    );
  }

  return (
    <>
      <section className="bg-white flex-fill">
        <div className="sop-form">
          <SectionTitle
            isAdmin={isAdmin(user)}
            section={sectionInstance.section}
            formTemplate={formInstance.form_template}
            showBom={handleBOM}
            showAdjustment={toggleBatchAdjust}
            duplicateSection={duplicateSection}
            returnToDashboard={returnToDashboard}
          />
          <FieldList
            fieldInstances={fieldInstances}
            standardYield={standardYield}
            handleFieldUpdate={handleFieldUpdate}
          />
          <FormCommentsContainer />
        </div>
      </section>
      <Navigator />
    </>
  );
};

const mapStateToProps = (state, ownProps) => ({
  ...ownProps,
  user: state.authReducer.user,
  formTemplate: state.formTemplateReducer[0],
  formInstance: state.formInstanceReducer.activeForm,
});

const mapDispatchToProps = (dispatch) => ({
  formInstanceActions: bindActionCreators(FormInstanceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FormSection);