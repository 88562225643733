import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'

import { FieldError } from './FieldError'

import * as FormAccessActions from "../../../actions/formAccessAction";
import { FieldInstanceProps } from '../../../formSchema';
import moment from 'moment';

interface SignatureProps {
  label?: string
  description?: string
  classes?: string
  value?: string
  user: any
  userList: any[]
  error?: string
  showRole: boolean
  instance: FieldInstanceProps
  updateInstance: (id: string) => void
  timestamp?: string
}

export const Signature = ({
  label,
  description,
  classes,
  value,
  user,
  userList,
  error,
  showRole,
  instance,
  updateInstance,
  timestamp
}: SignatureProps) => {

  const [signature, setSignature] = useState(
    value ? userList.find((user) => user.id === parseInt(value)) : null
  )
  const [formRole, setFormRole] = useState('')
  
  useEffect(() => {
    if (!instance) return

    if (!showRole) return

    if (!userList || userList.length === 0) return

    const memberRes = FormAccessActions.fetchFormAccess(instance.form_instance_id)

    memberRes.payload.then((members) => {
      setFormRole(members.find(mem => mem.user_id === user.user_id)?.form_role || 'Org Admin')
    })
  }, [showRole, userList, instance, user.user_id])

  useEffect(() => {
    if (value) {
      setSignature(userList.find((user) => user.id === parseInt(value)))
    }
  }, [value, userList])

  const handleButtonClick = () => {
    setSignature(user)
    updateInstance(user.user_id + '')
  }

  return (
    <div
      className={`form-input my-3  ${classes || 'col-sm-6'}`}
      style={{ display: 'flex', flexDirection: 'row' }} 
    >
      <div className='tw-pb-1 tw-flex tw-items-center'>
        {label && <label className='mb-0'>{label}:</label>}
      </div>
      <div className={label? 'tw-ml-2': ''}>
        {signature ? (
          <div className="w-100">
            {signature.first_name[0].toUpperCase()}.{signature.last_name[0].toUpperCase()}
            {timestamp && <small> - {moment(timestamp).calendar()}</small>}
            {formRole && <small className='tw-capitalize '><br/>{formRole}</small>}
          </div>
        ) : (
          <button onClick={handleButtonClick} className="btn btn-primary w-100">
            {description || 'Add Initial'}
          </button>
        )}
         {error && <FieldError error={error} />} 
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps): SignatureProps => ({
  user: state.authReducer.user,
  userList: state.userReducer.userList,
  ...ownProps
})

export default connect(mapStateToProps)(Signature)