import React from 'react'

interface ProcessPointProps {
    value: string
    description?: string
}

export const ProcessPoint = ({ value, description }: ProcessPointProps) => {
  return (
    <p className="mb-0 mr-4">
      <b className="text-primary">{value}</b>
      {description && (
        <>
          <br />
          {description}
        </>
      )}
    </p>
  )
}