import React from 'react';
import { browserHistory, Link } from 'react-router';
import ScrollArea from 'react-scrollbar';
import Custom from '../../utils/custom.js';
import { isAdmin } from '../../utils/utils';
import { Feature } from '@empiric/empiric-flagged-react';

export class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: true,
    };
  }
  componentDidMount() {
    Custom();
    if (!this.props.isLoggedIn) {
      browserHistory.push('/auth/login');
    }
  }

  componentWillUnmount() {}

  componentWillReceiveProps(nextProps) {
    if (!nextProps.isLoggedIn) browserHistory.push('/auth/login');
  }

  addAdminOption(path, label) {
    return isAdmin(this.props.user) ? (
      <li>
        <Link
          className={`treeview-item ${
            window.location.pathname.includes(path) ? 'active' : ''
          }`}
          to={path}
        >
          {label}
        </Link>
      </li>
    ) : null;
  }

  render() {
    return (
      <aside
        className={`app-sidebar ${this.state.isCollapsed ? 'collapsed' : ''}`}
        onMouseEnter={() => this.setState({ isCollapsed: false })}
        onMouseLeave={() => this.setState({ isCollapsed: true })}
      >
        <ScrollArea
          speed={0.8}
          className='area app-menu'
          contentClassName='content'
          horizontal={false}
        >
          <ul className='appmenu '>
            {/*<li>*/}
            {/*<Link to="/" className={`app-menu__item ${(window.location.pathname === '/') ? 'active' : ''}`}>*/}
            {/*<i className="app-menu__icon fa fa-circle text-primary icn-circle-bg"></i><span className="app-menu__label">Dashboard</span>*/}
            {/*</Link>*/}
            {/*</li>*/}
            <li>
              <Link
                className={`app-menu__item ${
                  window.location.pathname.startsWith('/incidents')
                    ? 'active'
                    : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
                to='/incidents'
              >
                <i className='fa-solid fa-exclamation-triangle'></i>
                <i className='app-menu__icon fa fa-dashboard invisible'></i>
                <span className='app-menu__label'>Incidents</span>
              </Link>
            </li>

            <li>
              <Link
                className={`app-menu__item ${
                  window.location.pathname.startsWith('/alerts') ? 'active' : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
                to='/alerts'
              >
                <i className='fa-solid fa-bell'></i>
                <i className='app-menu__icon fa fa-dashboard invisible'></i>
                <span className='app-menu__label'>Alerts</span>
              </Link>
            </li>

            {/* conditional render, if landingPage FF is enabled, only the custom dashboard tab will be shown */}
            {/* else custom dashboard and regular dashboard tabs are shown in tree like structure */}
            <Feature
              name='landingPageExperiment'
              render={(isEnabled) =>
                isEnabled ? (
                  <li>
                    <Link
                      className={`app-menu__item ${
                        window.location.pathname.startsWith(
                          '/environment/dashboard'
                        )
                          ? 'active'
                          : ''
                      } ${this.state.isCollapsed ? 'collapsed' : ''}`}
                      to='/environment/custom_dashboard'
                    >
                      <i className='app-menu__icon fa fa-dashboard invisible'></i>
                      <span className='app-menu__label'>Dashboard</span>
                    </Link>
                  </li>
                ) : (
                  <React.Fragment>
                    <hr className='app-sidebar-li-border' />
                    <li
                      className={`treeview ${
                        window.location.pathname.includes('dashboard')
                          ? 'is-expanded'
                          : ''
                      }`}
                    >
                      <a
                        className={`app-menu__item ${
                          this.state.isCollapsed ? 'collapsed' : ''
                        }`}
                        href='#'
                        data-toggle='treeview'
                      >
                        <i className='app-menu__icon fa fa-dashboard'></i>
                        <span className='app-menu__label'>Dashboards</span>
                        <i className='treeview-indicator fa fa-angle-down'></i>
                      </a>
                      <ScrollArea
                        speed={0.8}
                        className=''
                        contentClassName='content'
                        horizontal={false}
                      >
                        <ul className='treeview-menu'>
                          <li>
                            <Link
                              className={`treeview-item ${
                                window.location.pathname.startsWith(
                                  '/environment/dashboard'
                                )
                                  ? 'active'
                                  : ''
                              }`}
                              to='/environment/dashboard'
                            >
                              Dashboard
                            </Link>
                          </li>
                          <Feature name='newDashboardExperiment'>
                            <li>
                              <Link
                                className={`treeview-item ${
                                  window.location.pathname.startsWith(
                                    '/environment/custom_dashboard'
                                  )
                                    ? 'active'
                                    : ''
                                }`}
                                to='/environment/custom_dashboard'
                              >
                                Custom Dashboard
                              </Link>
                            </li>
                            {/* OEE DASHBOARD */}
                            <li>
                              <Link
                                className={`treeview-item ${
                                  window.location.pathname.startsWith(
                                    '/environment/oee_dashboard'
                                  )
                                    ? 'active'
                                    : ''
                                }`}
                                to='/environment/oee_dashboard'
                              >
                                OEE Dashboard
                              </Link>
                            </li>
                            {/* ASSETS GROUPS DASHBOARD */}
                            <li>
                              <Link
                                className={`treeview-item ${
                                  window.location.pathname.startsWith(
                                    '/environment/asset_groups'
                                  )
                                    ? 'active'
                                    : ''
                                }`}
                                to='/environment/asset_groups'
                              >
                                Asset Groups
                              </Link>
                            </li>
                          </Feature>
                        </ul>
                      </ScrollArea>
                    </li>
                    <hr className='app-sidebar-li-border' />
                  </React.Fragment>
                )
              }
            />

            <li>
              <Link
                className={`app-menu__item ${
                  window.location.pathname.startsWith('/forms') ? 'active' : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
                to='/forms'
              >
                <i className='fa-solid fa-list-ol'></i>
                <i className='app-menu__icon fa fa-dashboard invisible'></i>
                <span className='app-menu__label'>Forms</span>
              </Link>
            </li>

            <li>
              <Link
                className={`app-menu__item ${
                  window.location.pathname.startsWith('/reports/create_report')
                    ? 'active'
                    : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
                to='/reports/create_report'
              >
                <i className='fa-solid fa-sticky-note'></i>
                <i className='app-menu__icon fa fa-dashboard invisible'></i>
                <span className='app-menu__label'>Reports</span>
              </Link>
            </li>

            <Feature name='deviceMapExperiment'>
              <li>
                <Link
                  className={`app-menu__item ${
                    window.location.pathname.startsWith('/devicesmap/overview')
                      ? 'active'
                      : ''
                  } ${this.state.isCollapsed ? 'collapsed' : ''}`}
                  to='/devicesmap/overview'
                >
                  <i className='fa-solid fa-location'></i>
                  <i className='app-menu__icon fa fa-dashboard invisible'></i>
                  <span className='app-menu__label'>Device Map</span>
                </Link>
              </li>
            </Feature>

            <Feature name='assetsExperiment'>
              <li
                className={`treeview ${
                  window.location.pathname.includes('area') ? 'is-expanded' : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
              >
                <a className='app-menu__item' href='#' data-toggle='treeview'>
                  <i className='fa-solid fa-building'></i>
                  <i className='app-menu__icon fa fa-dashboard invisible'></i>
                  <span className='app-menu__label'>Areas</span>
                  <i className='treeview-indicator fa fa-angle-down'></i>
                </a>
                <ScrollArea
                  speed={0.8}
                  className=''
                  contentClassName='content'
                  horizontal={false}
                >
                  <ul className='treeview-menu'>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.includes('/areas')
                            ? 'active'
                            : ''
                        }`}
                        to='/areas'
                      >
                        Areas
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.includes('/area_types')
                            ? 'active'
                            : ''
                        }`}
                        to='/area_types'
                      >
                        Area Types
                      </Link>
                    </li>
                  </ul>
                </ScrollArea>
              </li>
            </Feature>

            <Feature name='assetsExperiment'>
              <li
                className={`treeview ${
                  window.location.pathname.includes('asset')
                    ? 'is-expanded'
                    : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
              >
                <a className='app-menu__item' href='#' data-toggle='treeview'>
                  <i className='fa-solid fa-toolbox'></i>
                  <i className='app-menu__icon fa fa-dashboard invisible'></i>
                  <span className='app-menu__label'>Assets</span>
                  <i className='treeview-indicator fa fa-angle-down'></i>
                </a>
                <ScrollArea
                  speed={0.8}
                  className=''
                  contentClassName='content'
                  horizontal={false}
                >
                  <ul className='treeview-menu'>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.includes('/assets')
                            ? 'active'
                            : ''
                        }`}
                        to='/assets'
                      >
                        Assets
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.includes('/asset_types')
                            ? 'active'
                            : ''
                        }`}
                        to='/asset_types'
                      >
                        Asset Types
                      </Link>
                    </li>
                  </ul>
                </ScrollArea>
              </li>
            </Feature>

            <Feature name='assetsExperiment'>
              <li
                className={`treeview ${
                  window.location.pathname.includes('device')
                    ? 'is-expanded'
                    : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
              >
                <a className='app-menu__item' href='#' data-toggle='treeview'>
                  <i className='fa-solid fa-microchip'></i>
                  <i className='app-menu__icon fa fa-dashboard invisible'></i>
                  <span className='app-menu__label'>Devices</span>
                  <i className='treeview-indicator fa fa-angle-down'></i>
                </a>
                <ScrollArea
                  speed={0.8}
                  className=''
                  contentClassName='content'
                  horizontal={false}
                >
                  <ul className='treeview-menu'>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.includes('/devices')
                            ? 'active'
                            : ''
                        }`}
                        to='/devices'
                      >
                        Devices
                      </Link>
                    </li>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.includes('/device_types')
                            ? 'active'
                            : ''
                        }`}
                        to='/device_types'
                      >
                        Device Types
                      </Link>
                    </li>
                  </ul>
                </ScrollArea>
              </li>
            </Feature>

            <hr className='app-sidebar-li-border' />
            <li>
              <Link
                className={`app-menu__item ${
                  window.location.pathname.startsWith('/workflows')
                    ? 'active'
                    : ''
                } ${this.state.isCollapsed ? 'collapsed' : ''}`}
                to='/workflows'
              >
                <i className={`fa-solid fa-gears`}></i>
                <i className='app-menu__icon fa fa-dashboard invisible'></i>
                <span className='app-menu__label'>Workflows</span>
              </Link>
            </li>

            {/*<Feature name="shipmentsExperiment">*/}
            {/*    <hr className="app-sidebar-li-border"/>*/}
            {/*    /!*<li>*!/*/}
            {/*    /!*    <Link className={`app-menu__item ${(window.location.pathname.startsWith('/shipments')) ? 'active' : ''}`} to="/shipments">*!/*/}
            {/*    /!*        <i className="app-menu__icon fa fa-dashboard invisible"></i><span className="app-menu__label">Shipments</span>*!/*/}
            {/*    /!*    </Link>*!/*/}
            {/*    /!*</li>*!/*/}
            {/*    <li className={`treeview ${(window.location.pathname.includes('shipment')) ? 'is-expanded' : ''} ${this.state.isCollapsed ? 'collapsed' : ''}`}>*/}
            {/*        <a className="app-menu__item" href="#" data-toggle="treeview"><i*/}
            {/*            className="app-menu__icon fa fa-shipping-fast"></i><span*/}
            {/*            className="app-menu__label">Shipments</span><i*/}
            {/*            className={`treeview-indicator fa fa-angle-down`}></i></a>*/}
            {/*        <ScrollArea*/}
            {/*            speed={0.8}*/}
            {/*            className=""*/}
            {/*            contentClassName="content"*/}
            {/*            horizontal={false}*/}
            {/*        >*/}
            {/*            <ul className="treeview-menu">*/}
            {/*                <li><Link*/}
            {/*                    className={`treeview-item ${(window.location.pathname.includes('/shipments')) ? 'active' : ''}`}*/}
            {/*                    to="/shipments">Shipments</Link></li>*/}
            {/*                <li><Link*/}
            {/*                    className={`treeview-item ${(window.location.pathname.includes('/shipment_contacts')) ? 'active' : ''}`}*/}
            {/*                    to="/shipment_contacts">Contacts</Link></li>*/}
            {/*            </ul>*/}
            {/*        </ScrollArea>*/}
            {/*    </li>*/}
            {/*</Feature>*/}

            <hr className='app-sidebar-li-border' />

            <li
              className={`treeview ${
                window.location.pathname.includes('settings')
                  ? 'is-expanded'
                  : ''
              } ${this.state.isCollapsed ? 'collapsed' : ''}`}
            >
              <a className='app-menu__item' href='#' data-toggle='treeview'>
                <i className='app-menu__icon fa fa-cog'></i>
                <span className='app-menu__label'>Settings</span>
                <i className={`treeview-indicator fa fa-angle-down`}></i>
              </a>
              <ScrollArea
                speed={0.8}
                className='setting-tab'
                contentClassName='content'
                horizontal={false}
              >
                <ul className={`treeview-menu`}>
                  {this.addAdminOption('/settings/general', 'General')}
                  {/*<li><Link className={`treeview-item ${(window.location.pathname.includes('/settings/general')) ? 'active' : ''}`} to="/settings/general">General</Link></li>*/}
                  {this.addAdminOption('/settings/users', 'Users')}
                  <li>
                    <Link
                      className={`treeview-item ${
                        window.location.pathname.includes('/settings/sensor')
                          ? 'active'
                          : ''
                      }`}
                      to='/settings/sensor'
                    >
                      Sensors
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`treeview-item ${
                        window.location.pathname.includes('/settings/gateway')
                          ? 'active'
                          : ''
                      }`}
                      to='/settings/gateway'
                    >
                      Gateways
                    </Link>
                  </li>
                  {this.addAdminOption('/settings/wifi', 'Wifi')}
                  <Feature name='probeExperiment'>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.includes('/settings/probes')
                            ? 'active'
                            : ''
                        }`}
                        to='/settings/probes'
                      >
                        Probes
                      </Link>
                    </li>
                  </Feature>
                  <Feature name='dataIntegrationExperiment'>
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.startsWith('/data/schemas')
                            ? 'active'
                            : ''
                        }`}
                        to='/data/schemas'
                      >
                        Data Integrations
                      </Link>
                    </li>
                  </Feature>
                  {isAdmin(this.props.user) ? (
                    <li>
                      <Link
                        className={`treeview-item ${
                          window.location.pathname.startsWith('/audits')
                            ? 'active'
                            : ''
                        }`}
                        to='/audits'
                      >
                        Audit Logs
                      </Link>
                    </li>
                  ) : (
                    <div />
                  )}
                  {/*<li><Link className={`treeview-item ${(window.location.pathname.includes('/settings/schedule_report')) ? 'active' : ''}`}  to="/settings/schedule_report">Scheduled Reports</Link></li>*/}
                  {/*{ this.addAdminOption("/settings/api_keys","API Keys") }*/}
                  {/*<li><Link className={`treeview-item ${(window.location.pathname.includes('/settings/device_map')) ? 'active' : ''}`}  to="/settings/device_map">Devices Map</Link></li>*/}
                </ul>
              </ScrollArea>
            </li>
            <hr className='app-sidebar-li-border' />
            <span className='xs-show-navbar-right-menu'>
              <li>
                <Link className='app-menu__item' to='/groups_devices'>
                  <span className='nav-icon-img mr-2'>
                    <img
                      src={require('../../styles/images/icon/ic_group_white.png')}
                      alt='ic_group'
                    />{' '}
                  </span>
                  <span className='app-menu__label'>Groups &amp; Devices</span>
                </Link>
              </li>
              <hr className='app-sidebar-li-border' />
            </span>
          </ul>
        </ScrollArea>
      </aside>
    );
  }
}
