import React, { useEffect, useState } from "react"
import { Link } from "react-router"
import { connect } from "react-redux";
import { FormInstanceProps } from "../../formSchema";


type StateProps = {
    // formTemplate: FormTemplateProps;
    formInstance: FormInstanceProps;
}

export const FormHeader = ({formInstance}) => {
    // Batch info: Work Center, Batch Number
    const [workCenter, setWorkCenter] = useState<string | null>();
    const [batchNumber, setBatchNumber] = useState<string | null>(null);
    const [lotNumber, setLotNumber] = useState<string | null>(null);

    const product_name = formInstance.form_template.product.name;

    useEffect(() => {
        // Confirm instance
        if (!formInstance) return;
        const headerFields = formInstance.header_fields;
        const work_center = headerFields.find(field => field.name === 'Work Center');
        const batch_number = headerFields.find(field => field.name === 'Batch Number');
        const lot_number = headerFields.find(field => field.name === 'Lot #');

        if (work_center) {
            setWorkCenter(work_center.value || '');
        }
        if (batch_number) {
            setBatchNumber(batch_number.value || '');
        }
        if(lot_number){
            setLotNumber(lot_number.value || '');
        }
    }, [formInstance]);

    return (
        <div className='p-4 mx-0 row row-cols-3 align-items-center'>
                <div>
                    {/* Back Button */}
                    <Link to='/forms'>
                        <i className='fa fa-angle-left' /> Dashboard
                    </Link>
                </div>
                <div className='text-center d-flex flex-column'>
                    <p className='mb-0'>Forms</p>
                    <p className='mb-0'>{product_name}</p>
                    {batchNumber && <p className="mb-0">Batch: {batchNumber}</p>}
                    {workCenter && <p className="mb-0">Work Center: {workCenter}</p>}
                    {lotNumber&& <p className="mb-0">Lot Number: {lotNumber}</p>}
                </div>
            </div>
    )
}

const mapStateToProps = (state): StateProps => ({
    formInstance: state.formInstanceReducer.activeForm
});


export default connect(mapStateToProps)(FormHeader);