import React from 'react'

interface FormNavProps {
  index: number
  maxIndex: number
  onBack: () => void
  onNext?: () => void
  children?: React.ReactNode
}

export const FormNavigator = ({
  index,
  maxIndex,
  onBack,
  onNext,
  children
}: FormNavProps) => {
  return (
    <div className="fixed py-4 form-navigator">
      <div className="mx-auto row">
        <div className="col-6">
          {(maxIndex === 1 || index > 0) && (
            <button className="btn text-primary" onClick={onBack}>
              <i className="mr-2 fa-solid fa-arrow-left"></i>
              Back
            </button>
          )}
        </div>
        <div className="col-6">
          <div className="row justify-content-end align-items-center">
            {children}
            {index < maxIndex && (
              <button className="btn btn-primary" onClick={onNext}>
                Next
              </button>
            )}
            {index >= maxIndex && (
              <button className="btn btn-primary" onClick={onNext}>
                Submit
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
