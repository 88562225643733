import { FormInstanceProps, SectionInstanceProps } from "../formSchema";

export const getPageSection = (form: FormInstanceProps, page: number): SectionInstanceProps => {

    const activeSectionInstance = form.section_instance.find(
        (section_instance) => {
            return parseFloat(section_instance.super_order_index) === page
        }
    ) as SectionInstanceProps;

    return activeSectionInstance;
}
