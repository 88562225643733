import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';
// Fetch all FormInstances
export const fetchFormInstances = () => {
    return axios.get(`${BASE_URL()}/api/v1/form_instances`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Fetch a single FormInstance by ID
export const fetchFormInstance = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/form_instances/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => {
        
        const sortedSectionInstances = response.data.section_instance
            .sort((a, b) => {
                const aIndex = a.super_order_index ?? a.section.order_index;
                const bIndex = b.super_order_index ?? b.section.order_index;
            
                if (aIndex === null) return 1; // Place `a` after `b` (nulls go to the end)
                if (bIndex === null) return -1; // Place `b` after `a` (nulls go to the end)
            
                return aIndex - bIndex; // Regular comparison
            });

        const updatedData = {
            ...response.data,
            section_instance: sortedSectionInstances
        }
        return updatedData;
    });
};

// Create a new FormInstance
export const createFormInstance = (formInstanceData) => {
    return axios.post(`${BASE_URL()}/api/v1/form_instances`, formInstanceData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Update an existing FormInstance
export const updateFormInstance = (id, formInstanceData) => {
    return axios.put(`${BASE_URL()}/api/v1/form_instances/${id}`, formInstanceData, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

// Delete a FormInstance
export const archiveFormInstance = (id) => {
    return axios.delete(`${BASE_URL()}/api/v1/form_instances/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};

export const exportFormInstance = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/pdfs/${id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
}


export const formInstanceBomFields = (id) => {
    return axios.get(`${BASE_URL()}/api/v1/form_instances/${id}/bom_fields`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
}

export const bulkUpdateFormAccess = (id, values) => {
    return axios
      .patch(
        `${BASE_URL()}/api/v1/form_instances/${id}/bulk_update_form_access`,
        { form_instance: { form_accesses_attributes: values } },
        {
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: storage.get('token')
          }
        }
      )
      .then((response) => response.data)
}