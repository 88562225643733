import React from 'react'

interface InstructionProps {
  value: string
  standardYield: string
  systemOfUnits?: string
  features?: {}
}

export const round = (value: number) => {
  return Math.round((value + Number.EPSILON) / 5) * 5
}

export const floor = (value: number) => {
  return Math.floor((value + Number.EPSILON) / 5) * 5
}
export const ceil = (value: number) => {
  return Math.ceil((value + Number.EPSILON) / 5) * 5
}

export const Instruction = ({
  value,
  standardYield,
  systemOfUnits = 'imperial',
  features
}: InstructionProps) => {
  // Implement conversion to weight using
  // *** organization unit system
  // *** standard yield, weight, for current batch

  // TODO: pass in some validation to convert weights 

  // Return placeholder for now if no standard yield
  if (!standardYield) {
    return <p className="sop-instruction">{value}</p>
  }

  let weight = 0
  let deviation = 0
  const percentIndex = value.indexOf('%')
  const units = systemOfUnits === 'imperial' ? 'lbs' : 'kg'

  if (percentIndex > -1) {
    const percent = parseFloat(value.slice(0, percentIndex)) / 100
    weight = round(percent * parseInt(standardYield))
    // TODO: Get deviation from validation
    deviation = weight * 0.001
  }

  const title = value.slice(percentIndex + 1, value.length)
  const isPercent = features?.hasOwnProperty('show_only_percent')
  const isWeight = features?.hasOwnProperty('show_only_weight')
  return (
    <>
      {isWeight && (
        <p className="sop-instruction">
          {weight}
        </p>
      )}
      {!isWeight && !isPercent && (
        <p className="sop-instruction">
        {weight} {units} of {value.slice(percentIndex + 1, value.length)}{' '}
        {floor(weight - deviation)} - {ceil(weight + deviation)} {units}{' '}
        {title && `of ${title} - `}{' '}
        <span className="font-italic font-weight-normal">
          ({value.slice(0, percentIndex)}%)
        </span>
      </p>
      )}
      {isPercent && (
        <p className="sop-instruction">
          <span className="font-italic font-weight-normal">
            {value.slice(0, percentIndex)}%
          </span>
        </p>
      )}
    </>
  )
}
