import React, { useState } from 'react'
import FieldTypes from './FormFields'
import { mapToFieldInstances, SORT_BY_ORDER_INDEX } from '../../utils/formUtils'
import { createFieldInstance } from '../../api/fieldInstanceApi'

const AdjustmentRow = ({ instance, childInstances, handleFieldUpdate }) => {
  const { field } = instance
  const childFields = field.children.sort(SORT_BY_ORDER_INDEX)
  const chemistFields = childFields.slice(0, -2)
  const operatorFields = childFields.slice(-2)

  const setClasses = (index, childField) => {
    let classes = ''
    if (index === 0) classes += ' col-sm-2 px-0'
    if (childField.name === 'Instructions') {
      classes += ' col-sm-6 px-2'
    } else {
      classes += ' col-sm-2 px-2'
    }
    if (index === field.children.length - 1) classes += ' col-sm-2 px-0'
    if (childField.field_type !== 'signature') classes += ' col-sm-2'

    return classes
  }

  return (
    <div className="row my-n4">
      <div className="py-2 col-8">
        <div className="d-flex align-items-center">
          {chemistFields.map((childField, index) => (
            <FieldTypes
              key={childField.id}
              hideLabel
              classes={setClasses(index, childField)}
              instance={childInstances.find(
                (child) => child.field_id === childField.id
              )}
              onChange={handleFieldUpdate}
            />
          ))}
        </div>
      </div>
      <div className="col-4">
        <div className="py-2 d-flex align-items-center">
          {operatorFields.map((childField) => (
            <FieldTypes
              key={childField.id}
              hideLabel
              classes="col-sm-6 px-2"
              instance={childInstances.find(
                (child) => child.field_id === childField.id
              )}
              onChange={handleFieldUpdate}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

const AdjustmentHeader = () => (
  <>
    <div className="mt-4 row align-items-center">
      <div className="px-0 col-8">
        <div className="header" data-type="chemist">
          Completed by Chemist
        </div>
        {/* Chemist fields here */}
      </div>
      <div className="px-0 col-4">
        <div className="header" data-type="operator">
          Completed by Operator
        </div>
        {/* Operator fields here */}
      </div>
    </div>

    <div className="py-2 mb-2 row align-items-center border-bottom header-row">
      <div className="col-8">
        <div className="d-flex align-items-center">
          {/* <div className="col-2">Time</div> */}
          <div className="col-2">Chemist Initial</div>
          <div className="col-2">Test ID</div>
          <div className="col-2">Original Results</div>
          <div className="col-6">Instructions</div>
        </div>
      </div>
      <div className="col-4">
        <div className="d-flex align-items-center">
          <div className="col-6">Adjusted Result</div>
          <div className="col-6">Operator Initials</div>
          {/* <div className="col-4">Time</div > */}
        </div>
      </div>
    </div>
  </>
)

export const BatchAdjustmentForm = ({
  batchAdjustmentFieldInstances: fieldInstances,
  formTemplate,
  handleFieldUpdate,
  addAdjustmentInstances
}) => {
  const [adjBtnDisabled, setAdjBtnDisabled] = useState(false)
  const [error, setError] = useState('')
  const [batchAdjs, setBatchAdjs] = useState(
    fieldInstances.filter((instance) =>
      instance.field.name.includes('batch_adjustment')
    )
  )
  // const [fieldInstances, setFieldInstances] = useState(batchAdjustmentFieldInstances)

  const startDate = fieldInstances.find(
    (instance) =>
      instance.field.name === 'Date' && instance.field.order_index === 1
  )
  const signtureField = fieldInstances.find(
    (instance) =>
      instance.field.name === 'Product Approved By' &&
      instance.field.order_index === 3
  )

  // Adding new adjustment to batch page
  const handleAddAdjustment = async () => {
    // Prevent from adding new adjustment while waiting for response
    setAdjBtnDisabled(true)

    // Validation chemist fields are filled in
    const chemistFields = batchAdjs[batchAdjs.length - 1].field.children.slice(
      0,
      -2
    )
    // Map chemist fields to instances
    // This will allow use to access the value of the field
    const childInstances = mapToFieldInstances(fieldInstances, chemistFields)

    // Check if all chemist fields are filled in
    if (!childInstances.every((child) => child.value)) {
      setError('Please complete the last adjustment')
      return
    }

    setError('')

    // Add field instance based on batch initial adjustment row and child fields
    const res = await createFieldInstance({ id: batchAdjs[0].id })

    // Map new children field instance to matching fields for ui display
    // Adding field to instance value
    const newAdjustment = res.data.child_instances.map((childInstance) => {
      const field = batchAdjs[0].field.children.find(
        (field) => field.id === childInstance.field_id
      )
      return { ...childInstance, field: field }
    })

    // Add new adjustment to batchAdjs
    addAdjustmentInstances(newAdjustment)
    // Remove child_instances from response
    delete res.data.child_instances

    // Add new adjustment to batchAdjs ui state
    setBatchAdjs((prev) => [...prev, res.data])

    // Enable add adjustment button
    setAdjBtnDisabled(false)
  }

  return (
    <div className="app-content batch-adjustment">
      <div className="pb-3 mb-2 border-bottom">
        <p className="sop-section-title">Batch Adjustment Worksheet</p>
      </div>

      <div>
        <p>
          <b>Product Name</b>: {formTemplate.product.name}
        </p>
        <FieldTypes
          classes="col-sm-3"
          instance={startDate}
          onChange={handleFieldUpdate}
        />

        <AdjustmentHeader />

        {batchAdjs.map((instance, index) => (
          <AdjustmentRow
            key={index}
            instance={instance}
            childInstances={fieldInstances
              .filter(
                (childInstance) => childInstance.parent_id === instance.id
              )
              .sort((a, b) => SORT_BY_ORDER_INDEX(a.field, b.field))}
            handleFieldUpdate={handleFieldUpdate}
          />
        ))}

        <div className="my-4 d-flex align-items-center">
          <FieldTypes
            classes={`col-4 px-2`}
            instance={signtureField}
            onChange={handleFieldUpdate}
          />
          <div className="text-right col-4">
            <p className="mb-2 text-danger">{error}&nbsp;</p>
            <button
              disabled={adjBtnDisabled}
              onClick={() => handleAddAdjustment()}
              className="btn btn-outline-primary"
            >
              <i className="mr-2 fa-solid fa-plus"></i>
              Add Adjustment
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
