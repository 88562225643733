
// SORT Field Instances
export const SORT_FIELD_INSTANCES = (a, b) =>
  SORT_BY_ORDER_INDEX(a.field, b.field)


// Sort Fields by Order Index
export const SORT_BY_ORDER_INDEX = (a, b) =>{
  const orderA = a.order_index ?? 0;
  const orderB = b.order_index ?? 0;
  return orderA - orderB;
}

// Map Field Instances
// This to get group child fields
export const mapToFieldInstances = (fieldInstances, children) => {
  return children
    .sort(SORT_BY_ORDER_INDEX)
    .map((field) =>
      fieldInstances.find((instance) => instance.field.id === field.id)
    )
}

export const findMissingFields = (fieldInstances) => {
  return fieldInstances
    .filter((field) => !field.value && field.field.is_required)
    .map((field) => ({ ...field, error: 'Required' }))
}

// TODO: Handle validation rules
