import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';

// Create a new FormInstance
export const duplicateSectionInstance = (id) => {
    return axios.post(`${BASE_URL()}/api/v1/section_instance`, id, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};
