import React, { useState, KeyboardEventHandler, useEffect } from 'react';

import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

interface Option {
  readonly label: string;
  readonly value: string;
}

const createOption = (label: string) => ({
  label,
  value: label,
});

const getInitalValues = (value: string) => {
  return value.split(',').map(createOption)
}

const MultiSelectLotNumber = ({
  label,
  value: initVal,
  onChange,
  formName,
  // onBlur,
  classes,
  // error
}) => {
  const [inputValue, setInputValue] = React.useState('')
  const [value, setValue] = React.useState<readonly Option[]>(initVal ? getInitalValues(initVal) : [])
  const isLineClearancePreWeighForm = formName === 'LINE CLEARANCE PRE-WEIGH'
  useEffect(() => {
    const formattedVal = value.map((val) => val.value).join(',')
    onChange(formattedVal)
  }, [value])

  const handleKeyDown: KeyboardEventHandler = (event) => {
    if (!inputValue) return
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        setValue((prev) => [...prev, createOption(inputValue)])
        setInputValue('')
        event.preventDefault()
    }
  }

  return (
    <div className={`my-3 tw-flex tw-items-center ${classes} ${(isLineClearancePreWeighForm)? 'tw-grid tw-grid-cols-2':'form-input' }`}>
      {label && <label className="mb-0 tw-mr-5 tw-items-center">{label}</label>}
      <div className="tw-w-full tw-max-w-[500px]">
        <CreatableSelect
          components={components}
          inputValue={inputValue}
          isMulti
          isClearable={false}
          menuIsOpen={false}
          // onBlur={onBlur}
          onChange={(newValue) => setValue(newValue)}
          onInputChange={(newValue) => setInputValue(newValue)}
          onKeyDown={handleKeyDown}
          placeholder="Press Enter"
          value={value}
          // styles={{
          //   control: (provided) => ({
          //     ...provided,
          //     height: '30px',
          //     minHeight: '30px'
          //   }),
          //   valueContainer: (provided) => ({
          //     ...provided,
          //     height: '30px',
          //     padding: '0 6px'
          //   }),
          //   input: (provided) => ({
          //     ...provided,
          //     height: '30px',
          //     margin: '0px'
          //   }),
          //   indicatorsContainer: (provided, state) => ({
          //     ...provided,
          //     height: '30px'
          //   })
          // }}
        />
      </div>
    </div>
  )
}
export default MultiSelectLotNumber;
