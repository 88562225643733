import React from 'react';

export interface StepCardProps {
  title: string;
  section: 'prev' | 'curr' | 'next';
  estimatedTime: number;
  userName?: string;
  actualTime?: number;
  progressPercentage?: number;
}

const getSectionTitle = (section: 'prev' | 'curr' | 'next') => {
  if (section === 'prev') return 'Previous Section';
  if (section === 'curr') return 'Current Section';
  if (section === 'next') return 'Next Section';
};

const calcPercentage = (actualTime: number, estimatedTime: number) => {
  return (actualTime / estimatedTime) * 100;
};

const ProgressBar = ({ progressPercent }: { progressPercent: number }) => (
  <div className='tw-flex tw-items-center tw-gap-2 tw-w-full'>
    <div className='tw-h-5 tw-rounded-full tw-bg-gray-200 tw-w-[270px]'>
      <div
        className='tw-h-full tw-rounded-full'
        style={{
          height: '100%',
          backgroundImage: 'linear-gradient(to right, #FFCF96, #C8FFBA)',
          width: `${progressPercent}%`,
        }}
      />
    </div>
    <span className='tw-text-black tw-text-[18px] tw-font-bold'>
      {progressPercent}%
    </span>
  </div>
);

const StepCard = ({
  title,
  userName,
  estimatedTime,
  actualTime,
  section,
}: StepCardProps) => {
  const isCurrent = section === 'curr';
  const progressPercent = actualTime
    ? calcPercentage(actualTime, estimatedTime)
    : 0;

  return (
    <div className='tw-flex tw-flex-col tw-items-start tw-gap-2'>
      {/* Section description */}

      <div className='tw-flex tw-items-center'>
        {isCurrent && (
          <i
            className={`fa-solid fa-play tw-mr-3 tw-text-blue-400 tw-text-[18px]`}
          />
        )}

        <p className='mb-0 tw-text-gray-500 tw-text-[20px] tw-border-none tw-outline-none'>
          {getSectionTitle(section)}
        </p>
      </div>
      {/* Divider */}
      <hr className='tw-border-gray-300 tw-w-full' />
      {/* Title and active user */}
      <h2 className='tw-font-bold tw-text-black tw-text-[18px]'>{title}</h2>
      <p className='tw-text-black tw-text-[18px]'>{userName || '\u00A0'}</p>

      {/* Progress Bar and time */}
      {isCurrent && (
        <>
          <ProgressBar progressPercent={progressPercent} />
          <p className='tw-text-black tw-text-[18px] tw-font-normal'>
            Current step duration: {actualTime} hrs
          </p>
        </>
      )}

      {/* Estimated time */}
      <h3 className='tw-font-bold tw-text-black tw-text-[18px]'>
        Estimated:{' '}
        <span className='tw-ml-2 tw-font-normal tw-text-[18px]'>
          {estimatedTime} hours
        </span>
      </h3>

      {/* Actual time */}
      {actualTime && section === 'prev' && (
        <h3 className='tw-font-bold tw-text-black tw-text-[18px]'>
          Actual:{' '}
          <span className='tw-ml-2 tw-font-normal'>{actualTime} hours</span>
        </h3>
      )}
    </div>
  );
};

export default StepCard;
