import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import TableBody, { TableHeader } from './Table';

import { isAdmin } from '../../utils/utils';
import {
  FormInstanceProps,
} from '../../formSchema';
import * as FormInstanceActions from '../../actions/formInstanceAction';

import '../../styles/SOPDashboard.css';
import LoadingMessage from '../LoadingMessage/LoadingMessage';
import { Accordion } from './Accordian';
import { userData } from '../../utils/fetchUserData';
import swal from 'sweetalert';
import { generatePdf } from '../../utils/sopPdfGenerator';

interface StateProps {
  user: any;
  formInstances: FormInstanceProps[];
}

interface DispatchProps {
  actions: any;
}

interface Props extends StateProps, DispatchProps {}

const NewFormButton = () => (
  <div className='col-sm-12'>
    <div className='row align-items-start' style={{ marginTop: '20px' }}>
      <Link to='/forms/assign'>
        <button className='btn btn-primary emp-type-btn-primary'>
          <i className='mr-1 fa fa-plus' />
          New Form
        </button>
      </Link>
    </div>
  </div>
);



const downloadPdf = (pdfData, filename) => {
  const link = document.createElement('a');
  link.href = pdfData;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const SOPDashboardPage = ({ user, formInstances, actions }: Props) => {
  const adminUser = isAdmin(user);
  const [selectedForms, setSelectedForms] = useState<string[]>([]);

  useEffect(() => {
    adminUser && userData();

    actions.fetchFormInstances();
  }, []);

  const toggleSelected = (id: string) => {
    setSelectedForms((prev) => {
      if (prev.includes(id)) {
        return prev.filter((formId) => formId !== id);
      }
      return [...prev, id];
    });
  };
  const exportInstances = async () => {
    // console.log('selectedForm', selectedForms);
    if (!selectedForms.length) {
      swal('No form selected');
      return;
    }

    // Incrementially fetch form data from server
    // Then create a PDF file from the data
    // Then download the file

    for (const formId of selectedForms) {
      const formData = await actions.exportFormInstance(formId);

      const pdfData = await generatePdf(formData.value);

      downloadPdf(pdfData, `form_${formId}.pdf`);
    }
  };

  const removeInstance = async (id: string) => {
    await actions.archiveFormInstance(id);
  };

  // console.log('formInstances', formInstances)
  const newForms = useMemo(
    () => formInstances.filter((form) => form.status === 'ready'),
    [formInstances]
  );
  const inProgressForms = useMemo(
    () => formInstances.filter((form) => form.status === 'in_progress'),
    [formInstances]
  );
  const completedForms = useMemo(
    () => formInstances.filter((form) => form.status === 'completed'),
    [formInstances]
  );
  const rejectedForms = useMemo(
    () =>
      adminUser && formInstances.filter((form) => form.status === 'rejected'),
    [formInstances]
  );

  if (!formInstances) {
    return <LoadingMessage />;
  }

  return (
    <main className='app-content sop-dashboard'>
      <section className='emp_org_settings_sec emp_report_sec emp_circle_box_sec'>
        <div className='py-2 row border-bottom d-flex justify-content-between'>
          <div className='ml-4' style={{ color: 'black', fontSize: '18px' }}>
            All Forms
          </div>
          <div className='filter-options'>
            {/* <span className='mr-4'>
                            <i className='fa fa-filter' /> Filter
                        </span>
                        <span className='mr-4'>
                            <i className='fa fa-sort' /> Sort
                        </span> */}
            <button
              className='mr-2 btn btn-outline-secondary'
              onClick={() => exportInstances()}
            >
              <i className='fa fa-file-export' /> Export
            </button>
          </div>
        </div>

        {adminUser && <NewFormButton />}

        {[newForms, inProgressForms, completedForms, rejectedForms].map(
          (formList, index) => {
            if (formList.length > 0) {
              return (
                <Accordion key={index} title={formList[0].status}>
                  <div className='shadow'>
                    <TableHeader adminUser={adminUser} />
                    <TableBody
                      toggleSelected={toggleSelected}
                      removeInstance={removeInstance}
                      formList={formList}
                      adminUser={adminUser}
                    />
                  </div>
                </Accordion>
              );
            }
          }
        )}

        {!formInstances.length && (
          <div className='mt-5 text-center'>No forms available</div>
        )}
      </section>
    </main>
  );
};
const mapStateToProps = (state, ownProps): StateProps => ({
  ...ownProps,
  formInstances: state.formInstanceReducer.instances,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(FormInstanceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SOPDashboardPage);
