import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as AuthActions from '../../actions/authActions';
import Header from '../../components/Header/Header';
import { Sidebar } from '../../components/Sidebar/Sidebar';
import { withRouter } from 'react-router';
import NewDashboardListView from '../../components/Environment/NewDashboardListView';

export class EnvironmentDashboardContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { authActions, auth } = this.props;
    return (
      <div className='app sidebar-mini rtl emp-sidebar'>
        <Header
          isLoggedIn={auth.isLoggedIn}
          user={auth.user}
          logout={authActions.logout}
          headerclassName='app-header'
        />
        <Sidebar
          isLoggedIn={auth.isLoggedIn}
          user={auth.user}
          logout={authActions.logout}
          headerclassName='app-header'
        />
        <NewDashboardListView />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  auth: state.authReducer,
});

const mapDispatchToProps = (dispatch) => ({
  authActions: bindActionCreators(AuthActions, dispatch),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EnvironmentDashboardContainer)
);
