import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import moment from 'moment'

import swal from 'sweetalert'

import { CommentProps, FormInstanceProps } from '../../../formSchema'
import * as FormCommentActions from '../../../actions/formCommentsAction'

interface StateProps {
  user: { user_id: string }
  formInstance: FormInstanceProps
}

interface DispatchProps {
  formCommentActions: typeof FormCommentActions
}
interface Props extends StateProps, DispatchProps {}

const FormComment = ({ comment }: { comment: CommentProps }) => (
  <div className="mb-2 border-bottom">
    <p className="mb-0 font-italic">
      <small>{moment(comment.created_at).calendar()}</small>
    </p>
    <p className="mb-0">
      <b>
        {comment.first_name} {comment.last_name}
      </b>{' '}
      - {comment.email}
    </p>
    <p className="mb-1">{comment.value}</p>
  </div>
)

const AddComment = ({ submitComment }) => {
  const [comment, setComment] = useState('')
  const [error, setError] = useState('')

  // Validate comment is appropriate to submit
  const validateComment = (comment) => {
    if (comment.length < 3) {
      setError('Comment must be at least 3 characters')
      return false
    }

    setError('')
    return true
  }

  const handleChange = (e) => {
    // Clear error if matches criteria
    if (error) {
      validateComment(e.target.value)
    }
    // Update comment value
    setComment(e.target.value)
  }

  const handleSubmit = async () => {
    // Validate comment
    const valid = validateComment(comment)
    if (!valid) {
      return
    }

    // Submit to backend
    const success = await submitComment(comment)

    // Clear comment if successful submission
    if (success) {
      setComment('')
    }
  }

  return (
    <div className="mb-2">
      {error && <p className="mb-0 text-danger">{error}</p>}
      <textarea
        value={comment}
        onChange={handleChange}
        className="form-control"
        rows={3}
        placeholder="Add a comment..."
      />
      <button onClick={() => handleSubmit()} className="my-2 btn btn-secondary">
        Save Comment
      </button>
    </div>
  )
}

export const FormCommentsContainer = ({
  user,
  formInstance,
  formCommentActions
}: Props) => {
  const [comments, setComments] = useState<CommentProps[]>([])

  useEffect(() => {
    // TODO: Limit to 10 most recent comments
    // Fetch comments from backend
    setComments(formInstance.form_comments)
  }, [formInstance])

  // Submit comment to backend
  const submitComment = async (comment) => {
    try {
      // Submit to backend
      const newComment = (await formCommentActions.submitFormComment({
        value: comment,
        user_id: user.user_id,
        form_instance_id: formInstance.id
      })) as any

      // Update comments state
      setComments([newComment.value, ...comments])
      // Return success
      return true
    } catch (error) {
      // Display error message
      swal('', 'Error saving comment', 'error')
      // Return failure
      return false
    }
  }

  return (
    <div className="mt-5">
      <AddComment submitComment={submitComment} />
      {comments.map((comment, index) => (
        <FormComment key={index} comment={comment} />
      ))}
    </div>
  )
}

const mapStateToProps = (state, ownProps): StateProps => ({
  ...ownProps,
  user: state.authReducer.user,
  formInstance: state.formInstanceReducer.activeForm
})
const mapDispatchToProps = (dispatch): DispatchProps => ({
  formCommentActions: bindActionCreators(FormCommentActions, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FormCommentsContainer)
