import React from 'react'

interface FieldErrorProps {
  error: string
}

export const FieldError = ({ error }: FieldErrorProps) => {
  return error ? (
    <div className='ml-auto text-danger'>{error}</div>
  ) : null
}
