import React from 'react';
import { OEEFormCard } from './OEEFormCard';

const assetGroupsDetail = [
  {
    product: 'Red Paints',
    start_Time: '7:45AM',
    batch_Number: 'RP-001',
    estimated_Remaining_Duration: '6.25 hours',
  },
  {
    product: 'Blue Paints',
    start_Time: '9:30AM',
    batch_Number: 'BP-002',
    estimated_Remaining_Duration: '6.75 hours',
  },
];

const title = 'Asset Group 1, Paints';

const NewOEEDashboardView = () => {
  return (
    <main className='app-content'>
      <div className='app-title border-bottom'>
        <h1 className='tw-font-normal'>OEE Dashboard</h1>
      </div>
      <div
        className='tw-p-10 rounded-lg tw-max-w-[1200px]'
        style={{ backgroundColor: '#F5F9FF' }}
      >
        {/* Main Heading Title */}
        <h1
          className='tw-text-[20px] tw-font-light'
          style={{ color: '#6D6C6C' }}
        >
          {title}
        </h1>
        {/* Main Page Content */}
        {assetGroupsDetail.map((data) => (
          <OEEFormCard key={data.batch_Number} titleData={data} />
        ))}
      </div>
    </main>
  );
};

export default NewOEEDashboardView;
