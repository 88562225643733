import React from 'react';
import StepCard, { StepCardProps } from './OEEStepCard';

interface SectionDataProps {
  previous: StepCardProps;
  current: StepCardProps;
  next: StepCardProps;
}

const SectionData: SectionDataProps = {
  previous: {
    section: 'prev',
    title: 'PRE-MIXING',
    userName: 'John Doe',
    estimatedTime: 1.25,
    actualTime: 1.1,
  },
  current: {
    section: 'curr',
    title: 'GRINDING',
    userName: 'John Doe',
    estimatedTime: 2.5,
    actualTime: 1.75,
  },
  next: {
    section: 'next',
    title: 'LET-DOWN',
    estimatedTime: 1.5,
  },
};

export const OEEFormCard = ({ titleData }) => {
  return (
    <div className='tw-bg-white tw-mx-auto tw-mt-10'>
      {/* Title */}
      <div
        className='tw-flex tw-gap-7 tw-p-5 tw-justify-between'
        style={{ backgroundColor: '#DCEAFF' }}
      >
        {Object.entries(titleData).map(([key, value]) => (
          <p key={key} className='mb-0 tw-text-black tw-text-[18px]'>
            <span className='tw-font-bold tw-capitalize'>
              {key.replaceAll('_', ' ')}:
            </span>{' '}
            {value as string}
          </p>
        ))}
      </div>

      {/* First Section */}
      <div className='tw-grid tw-grid-cols-3 tw-gap-8 tw-items-start tw-p-10'>
        {/* Previous Step */}
        <StepCard {...SectionData.previous} />
        {/* Current Step */}
        <StepCard {...SectionData.current} />
        {/* Next Step */}
        <StepCard {...SectionData.next} />
      </div>
    </div>
  );
};
