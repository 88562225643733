import axios from 'axios';
import storage from "../utils/storage";
import { BASE_URL } from '../config';


// Fetch a single FormTemplate by ID
export const fetchFormSection = (section_id) => {
    return axios.get(`${BASE_URL()}/api/v1/section_instance/${section_id}`, {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': storage.get('token')
        }
    }).then(response => response.data);
};
